<script setup lang="ts">
    defineProps({
        markup: String,
        noGrid: Boolean,
        display: String,
    });

    const appStore = useAppStore();
</script>

<template>
    <template v-if="display === 'hint'">
        <div class="text-block mx-auto max-w-[790px] py-6 text-sm text-woom-grey-moon max-md:mx-6">
            <div class="flex items-center gap-2">
                <woom-icon-bulb class="h-6 w-6 fill-current text-woom-grey-moon" />
                <div class="text-woom-grey-moon">{{ $t('tip') }}:</div>
            </div>
            <div
                v-if="markup"
                class="text-box">
                <renderer-html :content="textLinks(markup, appStore.getReference)" />
            </div>
        </div>
    </template>
    <template v-else>
        <div
            v-if="markup"
            :class="['text-block max-w-[790px] py-6 max-md:mx-6', { 'mx-auto': !noGrid }]"
            :display="display">
            <renderer-html :content="textLinks(markup, appStore.getReference)" />
        </div>
    </template>
</template>

<style scoped>
    .text-block > :deep(*:not(:last-child)) {
        @apply mb-4;
    }
    .text-block :deep(h2) {
        @apply text-2xl font-bold md:text-3xl;
    }
    .text-block :deep(h4) {
        @apply text-xl font-bold;
    }
    .text-block :deep(ol) {
        @apply flex list-outside  list-decimal flex-col gap-1 py-2 pl-5;
    }
    .text-block :deep(li + li) {
        @apply mt-2;
    }
    .text-block :deep(ul) {
        @apply flex list-outside list-disc flex-col gap-1 py-2 pl-5;
    }
    .text-block :deep(p a),
    .text-block :deep(ul a),
    .text-block :deep(ol a) {
        @apply underline transition-colors hover:text-woom-red;
    }
    .text-block :deep(table) {
        @apply w-full;
    }
    .text-block :deep(tr:nth-child(odd)) {
        @apply bg-woom-white-smoke;
    }
    .bg-woom-grey .text-block :deep(tr:nth-child(odd)) {
        @apply bg-woom-white;
    }
    .bg-woom-black .text-block :deep(tr:nth-child(odd)) {
        @apply bg-opacity-10 text-woom-white-smoke;
    }
    .bg-woom-green-moss .text-block :deep(tr:nth-child(odd)) {
        @apply bg-opacity-20;
    }
    .text-block :deep(td) {
        @apply p-4 text-sm;
    }
    .text-link.woom-link {
        @apply underline;
    }
</style>
